@import '_normalize.css'
@import '_variables'
@import '_icon_font'


.highlight .c, .highlight .cm, .highlight .c1, .highlight .cs {
  color: #909090;
}

.highlight, .highlight .w {
  background-color: $code-bg;
}

@import '_monokai.css'

/*
Copyright 2008-2013 Concur Technologies, Inc.

Licensed under the Apache License, Version 2.0 (the "License"); you may
not use this file except in compliance with the License. You may obtain
a copy of the License at

  http://www.apache.org/licenses/LICENSE-2.0

Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
License for the specific language governing permissions and limitations
under the License.
*/
////////////////////////////////////////////////////////////////////////////////
// GENERAL STUFF
////////////////////////////////////////////////////////////////////////////////
html, body
  color: $main-text
  padding: 0
  margin: 0
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  @extend $default-font
  background-color: $main-bg
  height: 100%
  -webkit-text-size-adjust: none
  /* Never autoresize text */
////////////////////////////////////////////////////////////////////////////////
// TABLE OF CONTENTS
////////////////////////////////////////////////////////////////////////////////
#toc > ul > li > a > span
  float: right
  background-color: #2484FF
  border-radius: 40px
  width: 20px
embossed-bg()
  background: linear-gradient(to bottom, rgba(black, 0.2), rgba(black, 0) 8px), linear-gradient(to top, rgba(black, 0.2), rgba(black, 0) 8px), linear-gradient(to bottom, rgba($nav-embossed-border-top, 1), rgba($nav-embossed-border-top, 0) 1.5px), linear-gradient(to top, rgba($nav-embossed-border-bottom, 1), rgba($nav-embossed-border-bottom, 0) 1.5px), $nav-subitem-bg
.tocify-wrapper
  transition: left 0.3s ease-in-out
  overflow-y: auto
  overflow-x: hidden
  position: fixed
  z-index: 30
  top: 0
  left: 0
  bottom: 0
  width: $nav-width
  background-color: $nav-bg
  font-size: 13px
  font-weight: bold
  // language selector for mobile devices
  .lang-selector
    display: none
    a
      padding-top: 0.5em
      padding-bottom: 0.5em
  // This is the logo at the top of the ToC
  & > img
    display: block
  & > .search
    position: relative
    input
      background: $nav-bg
      border-width: 0 0 1px 0
      border-color: $search-box-border-color
      padding: 6px 0 6px 20px
      box-sizing: border-box
      margin: $nav-v-padding $nav-padding
      width: ($nav-width - 30)
      outline: none
      color: $nav-text
      border-radius: 0
      /* ios has a default border radius */
    &:before
      position: absolute
      top: 17px
      left: $nav-padding
      color: $nav-text
      @extend $icon-search
  img+.tocify
    margin-top: $logo-margin
  .search-results
    margin-top: 0
    box-sizing: border-box
    height: 0
    overflow-y: auto
    overflow-x: hidden
    transition-property: height, margin
    transition-duration: 180ms
    transition-timing-function: ease-in-out
    &.visible
      height: 30%
      margin-bottom: 1em
    embossed-bg()
    li
      margin: 1em $nav-padding
      line-height: 1
    a
      color: $nav-text
      text-decoration: none
      &:hover
        text-decoration: underline
  .tocify-item>a, .toc-footer li
    padding: 0 $nav-padding 0 $nav-padding
    display: block
    overflow-x: hidden
    white-space: nowrap
    text-overflow: ellipsis
  // The Table of Contents is composed of multiple nested
  // unordered lists.  These styles remove the default
  // styling of an unordered list because it is ugly.
  ul, li
    list-style: none
    margin: 0
    padding: 0
    line-height: 28px
  li
    color: $nav-text
    transition-property: background
    transition-timing-function: linear
    transition-duration: 230ms
  // This is the currently selected ToC entry
  .tocify-focus
    box-shadow: 0px 1px 0px $nav-active-shadow
    background-color: $nav-active-bg
    color: $nav-active-text
  // Subheaders are the submenus that slide open
  // in the table of contents.
  .tocify-subheader
    display: none
    // tocify will override this when needed
    background-color: $nav-subitem-bg
    font-weight: 500
    .tocify-item>a
      padding-left: ($nav-padding + $nav-indent)
      font-size: 12px
    // for embossed look:
    embossed-bg()
    & > li:last-child
      box-shadow: none
      // otherwise it'll overflow out of the subheader
  .toc-footer
    padding: 1em 0
    margin-top: 1em
    border-top: 1px dashed $nav-footer-border-color
    li,a
      color: $nav-text
      text-decoration: none
    a:hover
      text-decoration: underline
    li
      font-size: 0.8em
      line-height: 1.7
      text-decoration: none
// button to show navigation on mobile devices
#nav-button
  span
    display: block
    $side-pad = $main-padding / 2 - 8px
    padding: $side-pad $side-pad $side-pad
    background-color: rgba($main-bg, 0.7)
    transform-origin: 0 0
    transform: rotate(-90deg) translate(-100%, 0)
    border-radius: 0 0 0 5px
  padding: 0 1.5em 5em 0
  // increase touch size area
  display: none
  position: fixed
  top: 0
  left: 0
  z-index: 100
  color: #000
  text-decoration: none
  font-weight: bold
  opacity: 0.7
  line-height: 16px
  img
    height: 16px
    vertical-align: bottom
  transition: left 0.3s ease-in-out
  &:hover
    opacity: 1
  &.open
    left: $nav-width
////////////////////////////////////////////////////////////////////////////////
// PAGE LAYOUT AND CODE SAMPLE BACKGROUND
////////////////////////////////////////////////////////////////////////////////
.page-wrapper
  margin-left: $nav-width
  position: relative
  z-index: 10
  background-color: $main-bg
  min-height: 100%
  padding-bottom: 1px
  // prevent margin overflow
  // The dark box is what gives the code samples their dark background.
  // It sits essentially under the actual content block, which has a
  // transparent background.
  // I know, it's hackish, but it's the simplist way to make the left
  // half of the content always this background color.
  .dark-box
    width: $examples-width
    background-color: $examples-bg
    position: absolute
    right: 0
    top: 0
    bottom: 0
  .lang-selector
    position: fixed
    z-index: 50
    border-bottom: 5px solid $lang-select-active-bg
.lang-selector
  background-color: $lang-select-bg
  width: 100%
  font-weight: bold
  a
    display: block
    float: left
    color: $lang-select-text
    text-decoration: none
    padding: 0 10px
    line-height: 30px
    outline: 0
    &:active, &:focus
      background-color: $lang-select-pressed-bg
      color: $lang-select-pressed-text
    &.active
      background-color: $lang-select-active-bg
      color: $lang-select-active-text
  &:after
    content: ''
    clear: both
    display: block
////////////////////////////////////////////////////////////////////////////////
// CONTENT STYLES
////////////////////////////////////////////////////////////////////////////////
// This is all the stuff with the light background in the left half of the page
.content
  // to place content above the dark box
  position: relative
  z-index: 30
  &:after
    content: ''
    display: block
    clear: both
  & > h1, & > h2, & > h3, & > h4, & > h5, & > h6, & > p, & > table, & > ul, & > ol, & > aside, & > dl
    margin-right: $examples-width
    padding: 0 $main-padding
    box-sizing: border-box
    display: block
    text-shadow: ($main-embossed-text-shadow)
  & > ul, & > ol
    padding-left: ($main-padding + 15px)
  // the div is the tocify hidden div for placeholding stuff
  & > h1, & > h2, & > div
    clear: both
  h1
    @extend $header-font
    font-size: 30px
    padding-top: 0.5em
    padding-bottom: 0.5em
    border-bottom: 1px solid #ccc
    margin-bottom: $h1-margin-bottom
    margin-top: 2em
    border-top: 1px solid #ddd
    background-image: linear-gradient(to bottom, white, #f9f9f9)
  h1:first-child, div:first-child + h1
    border-top-width: 0
    margin-top: 0
  h2
    @extend $header-font
    font-size: 20px
    margin-top: 4em
    margin-bottom: 0
    border-top: 1px solid #ccc
    padding-top: 1.2em
    padding-bottom: 1.2em
    background-image: linear-gradient(to bottom, rgba(white, 0.4), rgba(white, 0))
  // h2s right after h1s should bump right up
  // against the h1s.
  h1 + h2, h1 + div + h2
    margin-top: ($h1-margin-bottom * -1)
    border-top: none
  h3, h4, h5, h6
    @extend $header-font
    font-size: 15px
    margin-top: 2.5em
    margin-bottom: 0.8em
  h4, h5, h6
    font-size: 10px
  hr
    margin: 2em 0
    border-top: 2px solid $examples-bg
    border-bottom: 2px solid $main-bg
  table
    margin-bottom: 1em
    overflow: auto
    th,td
      text-align: left
      vertical-align: top
      line-height: 1.6
    th
      padding: 5px 10px
      border-bottom: 1px solid #ccc
      vertical-align: bottom
    td
      padding: 10px
    tr:last-child
      border-bottom: 1px solid #ccc
    tr:nth-child(odd) > td
      background-color: lighten($main-bg, 4.2%)
    tr:nth-child(even) > td
      background-color: lighten($main-bg, 2.4%)
  dt
    font-weight: bold
  dd
    margin-left: 15px
  p, li, dt, dd
    line-height: 1.6
    margin-top: 0
  img
    max-width: 100%
  code
    background-color: rgba(0, 0, 0, 0.05)
    padding: 3px
    border-radius: 3px
    @extend $break-words
    @extend $code-font
  pre>code
    background-color: transparent
    padding: 0
  aside
    padding-top: 1em
    padding-bottom: 1em
    text-shadow: (0 1px 0 lighten($aside-notice-bg, 15%))
    margin-top: 1.5em
    margin-bottom: 1.5em
    background: $aside-notice-bg
    line-height: 1.6
    &.warning
      background-color: $aside-warning-bg
      text-shadow: (0 1px 0 lighten($aside-warning-bg, 15%))
    &.success
      background-color: $aside-success-bg
      text-shadow: (0 1px 0 lighten($aside-success-bg, 15%))
  aside:before
    vertical-align: middle
    padding-right: 0.5em
    font-size: 14px
  aside.notice:before
    @extend $icon-info-sign
  aside.warning:before
    @extend $icon-exclamation-sign
  aside.success:before
    @extend $icon-ok-sign
  .search-highlight
    padding: 2px
    margin: -2px
    border-radius: 4px
    border: 1px solid #F7E633
    text-shadow: (1px 1px 0 #666666)
    background: linear-gradient(to top left, #f7e633 0%, #f1d32f 100%)
////////////////////////////////////////////////////////////////////////////////
// CODE SAMPLE STYLES
////////////////////////////////////////////////////////////////////////////////
// This is all the stuff that appears in the right half of the page
.content
  pre, blockquote
    background-color: $code-bg
    color: #fff
    padding: 2em $main-padding
    margin: 0
    width: $examples-width
    float: right
    clear: right
    box-sizing: border-box
    text-shadow: (0px 1px 2px rgba(0, 0, 0, 0.4))
    & > p
      margin: 0
    a
      color: #fff
      text-decoration: none
      border-bottom: dashed 1px #ccc
  pre
    @extend $code-font
  blockquote
    & > p
      background-color: $code-annotation-bg
      border-radius: 5px
      padding: $code-annotation-padding
      color: #ccc
      border-top: 1px solid #000
      border-bottom: 1px solid #404040
////////////////////////////////////////////////////////////////////////////////
// RESPONSIVE DESIGN
////////////////////////////////////////////////////////////////////////////////
// These are the styles for phones and tablets
// There are also a couple styles disperesed
@media (max-width: $tablet-width)
  .tocify-wrapper
    left: -1*$nav-width
    &.open
      left: 0
  .page-wrapper
    margin-left: 0
  #nav-button
    display: block
  .tocify-wrapper .tocify-item > a
    padding-top: 0.3em
    padding-bottom: 0.3em
@media (max-width: $phone-width)
  .dark-box
    display: none
  .tocify-wrapper .lang-selector
    display: block
  .page-wrapper .lang-selector
    display: none
  .content
    h1,h2,h3,h4,h5,h6,p,table,ul,ol,aside,dl
      margin-right: 0
      
    pre, blockquote
      float: none
      width: auto
